import React, { useState, useEffect, useRef } from 'react';

const TwitchClipPlayer = ({ socket }) => {
    const [clipUrl, setClipUrl] = useState('');
    const iframeRef = useRef(null);

    useEffect(() => {
        socket.on('clipUrl', (url) => {
            setClipUrl(url);
        });

        return () => {
            socket.off('clipUrl');
        };
    }, [socket]);

    useEffect(() => {
        const messageListener = (event) => {
            if (event.origin === 'https://clips.twitch.tv' && event.data === 'ended') {
                // Hide the player when clip ends
                setClipUrl('');
            }
        };

        window.addEventListener('message', messageListener);

        return () => {
            window.removeEventListener('message', messageListener);
        };
    }, []);

    const getEmbedUrl = (url) => {
        const clipId = url.split('/').pop();
        return `https://clips.twitch.tv/embed?clip=${clipId}&parent=shinydust.de&autoplay=true`;
    };

    const handleIframeLoad = () => {
        const iframe = iframeRef.current;
        if (iframe) {
            iframe.contentWindow.postMessage('ready', 'https://clips.twitch.tv');
        }
    };

    return (
        <div>
            {clipUrl ? (
                <iframe
                    ref={iframeRef}
                    src={getEmbedUrl(clipUrl)}
                    height="360"
                    width="640"
                    frameBorder="0"
                    scrolling="no"
                    allowFullScreen={true}
                    onLoad={handleIframeLoad}
                ></iframe>
            ) : (
                <p>No clip available</p>
            )}
        </div>
    );
};

export default TwitchClipPlayer;
