import React from "react";

import { useEffect, useState } from "react";

import BingoTable from "../components/BingoTable";
import EventLog from "../components/EventLog";
import SelectColor from "../components/SelectColor";
import PlayerInfo from "../components/PlayerInfo";

const MainPage = () => {

    return (
        <div className="App">
            <h1>Placeholder</h1>
        </div>
    );
};

export default MainPage;
