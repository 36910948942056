import React, { useState, useEffect } from 'react';

const TeamPoints = ({ socket }) => {
    const [teamPoints, setTeamPoints] = useState({
        blue: 0,
        green: 0,
        red: 0
    });

    useEffect(() => {
        // Listen for 'update_team_points' event
        socket.on('update_team_points', (points) => {
            // Filter out only the teams we care about
            setTeamPoints({
                red: points.red || 0,
                blue: points.blue || 0,
                green: points.green || 0
            });
        });

        // Cleanup the listener when the component is unmounted
        return () => {
            socket.off('update_team_points');
        };
    }, [socket]);

    // Team colors corresponding to their names
    const teamColors = {
        blue: 'blue',
        green: 'green',
        red: 'red'
    };

    // Team names translations
    const teamNames = {
        blue: 'Blau',
        green: 'Grün',
        red: 'Rot'
    };

    // Define the order of teams manually: Blau, Grün, Rot
    const teamOrder = ['blue', 'green', 'red'];

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            fontWeight: 'bold',
            gap: '20px',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',  // Grey background with 30% transparency
            padding: '3px',
            borderRadius: '5px'  // Optional: add some rounded corners for better aesthetics
        }}>
            {teamOrder.map((team) => (
                <div key={team} style={{ color: teamColors[team], fontSize: '40px' }}>
                    {teamNames[team]}: {teamPoints[team]}
                </div>
            ))}
        </div>
    );
};

export default TeamPoints;
