import React, {useEffect, useState} from "react";
import backgroundImage from "../Race-Background.jpg";
import RaceCamera from "../components/RaceCamera";
import BingoTable from "../components/BingoTable";


const Race = ({socket}) => {
    const [playerInfo] = useState({
        username: "None",
        team: "None",
    });

    const backgroundStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "1080px",
        width: "1920px",
    };

    const camera = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transform: "scale(0.3)",
        gap: "1150px",
    };

    const wrapperStyle = {
        position: "relative",
        marginLeft: "-70px", // Move 30px to the right
        top: "283px",
        width: "638px",
        transform: "scale(0.7)", // Scale down to 60%
        //border: "5px solid white",
    };

    const game = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transform: "scale(0.6)",
        marginTop: "-10px",
        gap: "40px",
    };

    return (
        <div>
            <div style={backgroundStyle}>
                <div style={camera}><RaceCamera socket={socket} eventString={"race-camera1"}/> <RaceCamera
                    socket={socket} eventString={"race-camera2"}/></div>
                <div style={wrapperStyle}><BingoTable socket={socket} playerInfo={playerInfo}/></div>
                <div style={game}><RaceCamera socket={socket} eventString={"race-game1"}/> <RaceCamera socket={socket}
                                                                                                       eventString={"race-game2"}/>
                </div>
            </div>
        </div>
    );
};

export default Race;
