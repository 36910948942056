// src/components/TwitchClipSender.js
import React, { useState } from 'react';


const TwitchClipSender = ({socket}) => {
    const [clipUrl, setClipUrl] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        if (clipUrl) {
            socket.emit('newTwitchClip', clipUrl);
            setClipUrl('');
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={clipUrl}
                    onChange={(e) => setClipUrl(e.target.value)}
                    placeholder="Enter Twitch Clip URL"
                />
                <button type="submit">Send Clip</button>
            </form>
        </div>
    );
};

export default TwitchClipSender;
