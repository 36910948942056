import React, {useEffect, useState} from "react";
import backgroundImage from "../Race-Background.jpg";
import RaceCamera from "../components/RaceCamera";
import RaceText from "../components/RaceText";
import BingoTable from "../components/BingoTable";

const RaceFourPeople = ({socket, arr}) => {
    const [playerInfo] = useState({
        username: "None",
        team: "None",
    });

    const backgroundStyle = {
        position: "relative",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "1080px",
        width: "1920px",
    };

    const game = {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        transform: "scale(0.65)",
        alignItems: "center",
        marginRight: "-570px",
        top: "-750px",
        gap: "40px",
    };

    const wrapperStyle = {
        position: "relative",
        marginLeft: "20px", // Move 30px to the right
        top: "200px",
        width: "638px",
        transform: "scale(0.9)", // Scale down to 60%
    };

    const opponents = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transform: "scale(0.215)",
        marginTop: "-1330px",
        marginRight: "-575px",
        gap: "40px",
    };

    // Function to get color based on the value in arr
    const getColor = (value) => {
        switch (value) {
            case 1:
                return "red";
            case 2:
                return "blue";
            case 3:
                return "yellow";
            case 4:
                return "green";
            default:
                return "transparent";
        }
    };

    return (
        <div>
            <div style={backgroundStyle}>
                <div style={wrapperStyle}><BingoTable socket={socket} playerInfo={playerInfo}/></div>
                <div style={game}>
                    <div style={{border: `10px solid ${getColor(arr[0])}`, backgroundColor: getColor(arr[0])}}>
                        <RaceCamera socket={socket} eventString={`race-game${arr[0]}`}/>
                    </div>
                </div>
                <div style={opponents}>
                    <div>
                        <div style={{transform: "scale(15)", position: "relative", top: "-80px"}}>
                            <RaceText socket={socket} eventString={`race-name${arr[1]}`}/>
                        </div>
                        <div style={{border: `10px solid ${getColor(arr[1])}`, backgroundColor: getColor(arr[1])}}>
                            <RaceCamera socket={socket} eventString={`race-game${arr[1]}`}/>
                        </div>
                    </div>
                    <div>
                        <div style={{transform: "scale(15)", position: "relative", top: "-80px"}}>
                            <RaceText socket={socket} eventString={`race-name${arr[2]}`}/>
                        </div>
                        <div style={{border: `10px solid ${getColor(arr[2])}`, backgroundColor: getColor(arr[2])}}>
                            <RaceCamera socket={socket} eventString={`race-game${arr[2]}`}/>
                        </div>
                    </div>
                    <div>
                        <div style={{transform: "scale(15)", position: "relative", top: "-80px"}}>
                            <RaceText socket={socket} eventString={`race-name${arr[3]}`}/>
                        </div>
                        <div style={{border: `10px solid ${getColor(arr[3])}`, backgroundColor: getColor(arr[3])}}>
                            <RaceCamera socket={socket} eventString={`race-game${arr[3]}`}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RaceFourPeople;
